<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="6" style="display: block">
          <a-form-item label="保险公司分类">
            <a-select :allowClear="true" style="width: 150px;" v-model="searchForm.BaseInsurance">
              <a-select-option v-for="n in insuranceBaseList" :key="n" :value="n">
                {{n}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="保险公司名称">
            <a-input placeholder="保险公司名称" v-model="searchForm.InsuranceName" />
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='保险公司工号'>
            <a-input placeholder="InsuranceAccount" v-model="searchForm.InsuranceAccount"/>
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display: block">
          <a-form-item label="状态">
            <a-select :allowClear="true" v-model="searchForm.Status">
              <a-select-option value="1">
                启用
              </a-select-option>
              <a-select-option value="-1">
                停用
              </a-select-option>
              <a-select-option value="-2">
                删除归档
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="生效时间">
            <a-range-picker v-model="searchForm.StartAt" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="失效时间">
            <a-range-picker v-model="searchForm.EndAt" />
          </a-form-item>
        </a-col>
        <a-col :span="6" :style="{ display: expand ? 'block' : 'none' }">
          <a-form-item label="建立时间">
            <a-range-picker v-model="searchForm.CreatedAt" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button icon="plus" type="primary" @click="addClick" v-if="$root.$data.privateState.action.includes('/Rate/CreateCar') || $root.$data.privateState.role === 'SuperAdmin'">添加政策</a-button>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.uuid"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #status="status">
          <a-tag :color="$statusEnum.rateStatus[status].color">{{$statusEnum.rateStatus[status].text}}</a-tag>
        </template>
        <template #rateList="rateList">
          <div v-for="it in rateList" :key="it.key" style="padding: 5px 0;">
            <div style="background-color: #fafafa;">
              <a-tag color="green">{{typeDic[it.type]}}</a-tag>
              {{it.rateString}}
            </div>
          </div>
        </template>
        <template #startAt="startAt">
          {{moment(startAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #endAt="endAt">
          {{endAt ? moment(endAt).format('YYYY-MM-DD HH:mm:ss') : "无"}}
        </template>
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #action="data">
          <a-button v-if="$root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Rate/EditByCar')" type="link" @click="toEdit(data)">编辑</a-button>
          <div v-if="$root.$data.privateState.role === 'SuperAdmin' || $root.$data.privateState.action.includes('/Rate/EditByCar')">
            <a-button v-if="data.status == 1" type="link" @click="changeStatus(data)">停用</a-button>
            <a-button v-if="data.status == -1" type="link" @click="changeStatus(data)">启用</a-button>
          </div>
          
        </template>
      </a-table>
    </div>
    <!-- table end -->
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import screenfull from 'screenfull'

import { Rate, Utils } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"BaseInsurance":"","InsuranceName":"","InsuranceAccount":"","StartAt":[],"EndAt":[],"CreatedAt":[],"Status":""}'
const columns = [
  {
    title: 'Uuid',
    width: 150,
    dataIndex: 'uuid',
  },
  {
    title: '状态',
    width: 150,
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '保险公司分类',
    width: 150,
    dataIndex: 'baseInsurance',
  },
  {
    title: '保险公司名称',
    width: 150,
    dataIndex: 'insuranceName',
  },
  {
    title: '保险公司工号',
    width: 150,
    dataIndex: 'insuranceAccount',
  },
  {
    title: '投放内容',
    width: 350,
    dataIndex: 'rateList',
    scopedSlots: { customRender: 'rateList' },
  },
  {
    title: '生效时间',
    width: 150,
    dataIndex: 'startAt',
    scopedSlots: { customRender: 'startAt' },
  },
  {
    title: '失效时间',
    width: 150,
    dataIndex: 'endAt',
    scopedSlots: { customRender: 'endAt' },
  },
  {
    title: '建立用户',
    width: 150,
    dataIndex: 'createdUserName',
  },
  {
    title: '建立时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'RateCarList',
  mixins: [aliveVM, mixinTableList],
  created () {
    const baseColumns = []
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
  },
  async mounted () {
    this.GetInsuranceBaseList()
    this.fetch()
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${Rate}/TableListByCar`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      // 其他
      moment,
      insuranceBaseList: [],
      typeDic: {
        TCI: '交强险',
        VCI: '商业险',
        And: '套单',
        Any: '不限'
      }
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'StartAt' || k === 'EndAt' || k === 'CreatedAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async GetInsuranceBaseList() {
      const apiRes = await this.$axios.get(`${Utils}/InsuranceBaseList`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.insuranceBaseList = apiRes.data
    },
    addClick() {
      this.$router.push({ name: 'RateCarForm' }).catch(err => err)
    },
    toEdit(data) {
      this.$router.push({ name: 'RateCarForm', params: {uuid: data.uuid} }).catch(err => err)
    },
    async changeStatus(data) {
      const apiRes = await this.$axios.put(`${Rate}/ChangeStatusByCar?uuid=${data.uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.fetch()
    }
  }
}
</script>

<style>

</style>